import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Layout } from "./utility/context/Layout";
import { IntlProviderWrapper } from "./utility/context/Internationalization";
import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/storeConfig/store";
import Spinner from "./components/@vuexy/spinner/Fallback-spinner";
import "./index.scss";
import "./@fake-db";
import App from "./App";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "react-app-polyfill/ie9";

import { ToastContainer } from 'react-toastify'

// const LazyApp = lazy(() => import("./App"))

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Spinner />}>
      <Layout>
        <IntlProviderWrapper>
          <App />
          <ToastContainer newestOnTop />
        </IntlProviderWrapper>
      </Layout>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);


serviceWorker.unregister();
