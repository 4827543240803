const initialState = {
  contacts: [],
  error: null,
  errorMsg: null,
  loading: false,
  sortIndex: [0, 0],
  totalRecords: 0
};

const getIndex = (arr3, perPage, currentPage,totalRecords) => {
  let startIndex = (perPage*(currentPage-1))+1
  let endIndex = perPage*currentPage
  if(totalRecords < endIndex){
    endIndex =totalRecords
  }
  let finalArr = [startIndex, endIndex]
  return (arr3 = finalArr)
}

export const contact = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_CONTACT":
    case "EDIT_CONTACT":
      return { ...state, loading: action.payload };
    case "GET_LIST_PAGINATE":
      return {
        ...state,
        loading: action.payload,
        contacts: [],
        sortIndex: [0, 0],
        totalRecords: 0
      };
    case "DELETE_CONTACT":
      return {
        ...state,
        loading: true,
        contactDelete: action.payload
      };
    case "ADD_CONTACT_SUCCESS":
      return {
        ...state, 
        loading: false,
        sortIndex: state.contacts.length === 0 ? [1,1] : state.sortIndex,
        totalRecords: state.contacts.length === 0 ? 1 : state.totalRecords + 1,
        totalPages: state.contacts.length === 0 ? 1 : state.totalPages,
        contacts: [...state.contacts, action.payload]
      };
    case "EDIT_CONTACT_SUCCESS":
      return {
        ...state,
        loading: false,
        contacts: state.contacts.map(c => c.id === action.payload.id ? c = action.payload : c ),
      };
    case "DELETE_CONTACT_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        contacts: state.contacts.filter(c => c.id !== state.contactDelete),
        contactDelete:null
      };
    case "GET_LIST_PAGINATE_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        contacts: action.payload.contacts,
        totalRecords: action.payload.total_items_count,
        rowsPerPage: action.payload.rows_per_page,
        totalPages: action.payload.total_pages,
        currentPage: action.payload.current_page,
        sortIndex: getIndex(state.sortIndex, action.payload.rows_per_page, action.payload.current_page, action.payload.total_items_count)
      };
    case "GET_LIST_SUCCESS":
      return { ...state, loading: false, error: false, contacts: action.payload };
    case "ADD_CONTACT_ERROR":
    case "EDIT_CONTACT_ERROR":
    case "DELETE_CONTACT_ERROR":
    case "GET_LIST_PAGINATE_ERROR":
      return { ...state, loading: false, error: true, errorMsg: action.payload };
    default:
      return { ...state };
  }
}