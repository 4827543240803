import { combineReducers } from "redux"
import { list } from "./listReducers"
import { segment } from "./segmentReducers"
import { contact } from "./contactReducers"

const contactsReducers = combineReducers({
  list,
  segment,
  contact
})

export default contactsReducers
