import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Layout size={20} />,
    permissions: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
    navLink: "/dashboard"
  },
  {
    id: "projects",
    title: "Projects",
    type: "item",
    icon: <Icon.BarChart size={20} />,
    permissions: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
    navLink: "/projects"
  },
  {
    id: "contacts",
    title: "Contacts",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"],
    navLink: "/contactList"
  },
  {
    id: "library",
    title: "Library",
    type: "collapse",
    icon: <Icon.Folder size={20} />,
    children: [
      {
        id: "solutions",
        title: "Solutions",
        type: "item",
        icon: <Icon.Monitor size={16} />,
        navLink: "/solutions",
        permissions: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"]
      },
      {
        id: "messages",
        title: "Messages",
        type: "item",
        icon: <Icon.Mail size={16} />,
        navLink: "/templates",
        permissions: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"]
      }
    ]
  },
  {
    id: "faq",
    title: "Help",
    type: "collapse",
    icon: <Icon.HelpCircle size={20} />,
    children: [
      {
        id: "importContacts",
        title: "Import contacts",
        type: "item",
        icon: <Icon.Download size={16} />,
        navLink: "/",
        permissions: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"]
      },
      {
        id: "designSurvey",
        title: "Design survey",
        type: "item",
        icon: <Icon.FileText size={16} />,
        navLink: "/",
        permissions: ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"]
      }
    ]
  },
]

export default navigationConfig
