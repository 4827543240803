import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import dataList from "./data-list/"
import utils from "./utils"
import setup from "./setup"
import projects from "./projects/"
import surveys from "./surveys/"
import contacts from "./contacts/"
import templates from "./templates/"
import profile from "./profile"
import solutions from "./solutions"
import contract from "./contract/index"
import dashboard from "./dashboard/index"

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,
  dataList: dataList,
  utils: utils,
  setup: setup,
  projects: projects,
  surveys: surveys,
  contacts: contacts,
  templates: templates,
  profile: profile,
  solutions: solutions,
  contract: contract,
  dashboard: dashboard
})

export default rootReducer
