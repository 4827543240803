const initialState = {
  error: null,
  loading: false,
  segment : null,
  conditionsChanged : false,
  contacts : [],
  sortIndex: [0, 0],
  totalRecords: 0
};

const getIndex = (arr3, perPage, currentPage,totalRecords) => {
  let startIndex = (perPage*(currentPage-1))+1
  let endIndex = perPage*currentPage
  if(totalRecords<endIndex){
    endIndex =totalRecords
  }
  let finalArr = [startIndex, endIndex]
  return (arr3 = finalArr)
}

export const segment = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SEGMENT_CONTACTS":
      return { ...state, loading: action.payload, contacts: [] };
    case "GET_SEGMENT":
    case "DELETE_CONDITION":
      return { ...state, loading: action.payload };
    case "ADD_SEGMENT_SUCCESS":
    case "GET_SEGMENT_SUCCESS":
      return {
        ...state, loading: false, segment: action.payload
      };
    case "ADD_SEGMENT":
    case "EDIT_CONDITIONS":
      return {
        ...state, loading: false, conditionsChanged: false
      };
    case "EDIT_CONDITIONS_SUCCESS":
    case "DELETE_CONDITION_SUCCESS":
      return { ...state, loading: false ,conditionsChanged: true};
    case "EDIT_SEGMENT":
      return { ...state, loading: false, segment: action.payload};
    case "ADD_SEGMENT_ERROR":
    case "EDIT_CONDITIONS_ERROR":
    case "DELETE_CONDITION_ERROR":
    case "GET_SEGMENT_CONTACTS_ERROR":
    case "GET_SEGMENT_ERROR":
      return { ...state, loading: false, error: action.payload };
    case "GET_SEGMENT_CONTACTS_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        contacts: action.payload.contacts,
        totalRecords: action.payload.total_items_count,
        rowsPerPage: action.payload.rows_per_page,
        totalPages: action.payload.total_pages,
        currentPage: action.payload.current_page,
        sortIndex: getIndex(state.sortIndex, action.payload.rows_per_page, action.payload.current_page, action.payload.total_items_count)
      };
    default:
      return { ...state };
  }
};

