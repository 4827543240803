const initialState = {
  company: {},
  users: [],
  error: null,
  loading: false,
  sortIndex: [0, 0],
  totalRecords: 0
};

const getIndex = (arr3, perPage, currentPage,totalRecords) => {
  let startIndex = (perPage*(currentPage-1))+1
  let endIndex = perPage*currentPage
  if(totalRecords<endIndex){
    endIndex =totalRecords
  }
  let finalArr = [startIndex, endIndex]
  return (arr3 = finalArr)
}

export const company = (state = initialState, action) => {
  switch (action.type) {
    case "GET_COMPANY":
    case "EDIT_COMPANY":
    case "ADD_USER":
    case "EDIT_USER":
      return { ...state, loading: action.payload };
    case "GET_USERS_PAGINATE":
      return { ...state, loading: action.payload, users: [] };
    case "GET_COMPANY_SUCCESS":
    case "EDIT_COMPANY_SUCCESS":
      return { ...state, loading: false, company: action.payload };
    case "ADD_USER_SUCCESS":
      return {
        ...state, loading: false, users: [...state.users, action.payload]
      };
    case "EDIT_USER_SUCCESS":
    return {
      ...state,
      loading: false,
      users: state.users.map(u => u.id === action.payload.id ? u = action.payload : u ),
    };
    case "GET_USERS_PAGINATE_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        users: action.payload.users,
        totalRecords: action.payload.total_items_count,
        rowsPerPage: action.payload.rows_per_page,
        totalPages: action.payload.total_pages,
        currentPage: action.payload.current_page,
        sortIndex: getIndex(state.sortIndex, action.payload.rows_per_page, action.payload.current_page, action.payload.total_items_count)
      };
    case "GET_COMPANY_ERROR":
    case "EDIT_COMPANY_ERROR":
    case "ADD_USER_ERROR":
    case "EDIT_USER_ERROR":
    case "GET_USERS_PAGINATE_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return { ...state };
  }
};