import jwtDecode from 'jwt-decode';

const token = localStorage.getItem("token")
let role = null
let decodedToken = null

if (token) {
  decodedToken = jwtDecode(token)
  role = decodedToken.roles.find(role => role !== "ROLE_USER")
}

const initialState = token
  ? { isLoggedIn: true, user: decodedToken.username, userRole: role, apiToken: null, error: false}
  : { isLoggedIn: false, user: null, userRole: null };

export const login = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "GENERATE_TOKEN":
      return { ...state, loading: true, error: false };
    case "LOGIN_WITH_JWT": {
      return { ...state, isLoggedIn: true, user: payload.user, userRole: payload.role };
    }
    case "LOGOUT": {
      return { ...state, isLoggedIn: false, user: null };
    }
    case "GENERATE_TOKEN_SUCCESS": {
      return { ...state, apiToken: payload.token };
    }
    case "GENERATE_TOKEN_ERROR": {
      return { ...state, error: payload };
    }
    // case "CHANGE_ROLE": {
    //   return { ...state, userRole: userRole };
    // }
    default: {
      return state;
    }
  }
};
