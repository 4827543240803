const initialState = {
  projects: [],
  lists: [],
  templates: [],
  templatesGratitude: [],
  tRatingPositive: [],
  tRatingNegative: [],
  filtersData: null,
  project: null,
  projectDataDashboard: null,
  error: null,
  loading: false,
  loadingDashboard: false,
  newTemplate: null,
  filterChanged: false,
};

export const project = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PROJECT":
    case "ADD_PROJECT":
    case "ADD_PROJECT_SURVEY":
    case "EDIT_SURVEY":
    case "EDIT_PROJECT":
      return {
        ...state,
        loading: action.payload,
        project: null,
        projectDataDashboard: null
      };
    case "GET_TEMPLATES_GRATITUDE":
    case "GET_T_RATING_POSITIVE":
    case "GET_T_RATING_NEGATIVE":
    case "ADD_FILTER":
    case "GET_PROJECTS":
      return {
        ...state,
        loading: action.payload,
      };
    case "GET_PROJECT_DASHBOARD":
      return {
        ...state,
        loadingDashboard: action.payload,
        projectDataDashboard: null
      };
    case "GET_FILTERS_DASHBOARD":
      return {
        ...state,
        loadingDashboard: action.payload,
        filtersData: null
      };
    case "EDIT_FILTER":
      return {
        ...state,
        loadingDashboard: action.payload,
        filterChanged: false,
        projectDataDashboard: null
      };
    case "DELETE_PROJECT":
      return {
        ...state,
        loading: true,
        projectDelete: action.payload
      };
    case "DELETE_FILTER":
      return {
        ...state,
        loadingDashboard: true,
        filterDelete: action.payload,
        filterChanged: false
      };
    case "ADD_PROJECT_SUCCESS":
    case "EDIT_SURVEY_SUCCESS":
      return {
        ...state,
        loading: false,
        projects: state.projects !== null ? [...state.projects, action.payload] : [action.payload],
        project: action.payload
      };
    case "DELETE_PROJECT_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        projects: state.projects.filter(c => c.id !== state.projectDelete),
        projectDelete: null
      };
    case "GET_PROJECTS_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        projects: action.payload,
      };
    case "ADD_PROJECT_SURVEY_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
      };
    case "GET_PROJECT_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        project: action.payload,
      };
    case "GET_TEMPLATES_GRATITUDE_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        templatesGratitude: action.payload,
      };
    case "GET_T_RATING_POSITIVE_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        tRatingPositive: action.payload,
      };
    case "GET_T_RATING_NEGATIVE_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        tRatingNegative: action.payload,
      };
    case "EDIT_PROJECT_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
      };
    case "DELETE_PROJECT_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        templates: state.templates.filter(t => t.id !== state.templateDelete),
        templateDelete: null
      };
    case "GET_PROJECT_DASHBOARD_SUCCESS":
      return {
        ...state,
        loadingDashboard: false,
        error: false,
        projectDataDashboard: action.payload,
      };
    case "ADD_FILTER_SUCCESS":
      if (action.payload.type === 'filter_subscriber') {
        return {
          ...state,
          loadingDashboard: false,
          filtersData: { ...state.filtersData, subscriberFilters: [...state.filtersData.subscriberFilters, action.payload] },
        };
      } else {
        return {
          ...state,
          loadingDashboard: false,
          filtersData: { ...state.filtersData, questionFilters: [...state.filtersData.questionFilters, action.payload] },
        };
      }

    case "EDIT_FILTER_SUCCESS":
      if (action.payload.type === 'filter_subscriber') {
        return {
          ...state,
          loadingDashboard: false,
          filterChanged: true,
          filtersData: { ...state.filtersData, subscriberFilters: state.filtersData.subscriberFilters.map(c => c.id === action.payload.id ? c = action.payload : c) },
        };
      } else {
        return {
          ...state,
          loadingDashboard: false,
          filterChanged: true,
          filtersData: { ...state.filtersData, questionFilters: state.filtersData.questionFilters.map(c => c.id === action.payload.id ? c = action.payload : c) },
        };
      }
    case "DELETE_FILTER_SUCCESS":
      return {
        ...state,
        loadingDashboard: false,
        error: false,
        filtersData: {
          ...state.filtersData,
          subscriberFilters: state.filtersData.subscriberFilters.filter(f => f.id !== state.filterDelete),
          questionFilters: state.filtersData.questionFilters.filter(f => f.id !== state.filterDelete),
        },
        filterDelete: null,
        filterChanged: true,
      };
    case "GET_FILTERS_DASHBOARD_SUCCESS":
      return {
        ...state,
        loadingDashboard: false,
        error: false,
        filtersData: action.payload
      };
    case "GET_PROJECTS_ERROR":
    case "GET_PROJECT_ERROR":
    case "ADD_PROJECT_ERROR":
    case "ADD_PROJECT_SURVEY_ERROR":
    case "EDIT_PROJECT_ERROR":
    case "EDIT_SURVEY_ERROR":
    case "GET_TEMPLATES_GRATITUDE_ERROR":
    case "GET_T_RATING_POSITIVE_ERROR":
    case "GET_T_RATING_NEGATIVE_ERROR":
    case "DELETE_PROJECT_ERROR":
    case "GET_PROJECT_DASHBOARD_ERROR":
    case "GET_FILTERS_DASHBOARD_ERROR":
    case "DELETE_FILTER_ERROR":
      return {
        ...state,
        loadingDashboard: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};
