import React, { Fragment } from "react";
import { connect } from "react-redux"

import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button
} from "reactstrap";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";

import { history } from "../../../history";
import Avatar from "../../../components/@vuexy/avatar/AvatarComponent";
import "../../../assets/scss/custom.scss";
import { FormattedMessage } from "react-intl"

const UserDropdown = (props) => {
  return (
    <DropdownMenu right>
      <DropdownItem tag="a" onClick={(e) => history.push("/profile")}>
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle"><FormattedMessage id="profile" /></span>
      </DropdownItem>
      <DropdownItem tag="a" onClick={(e) => history.push("/settings")}>
        <Icon.Settings size={14} className="mr-50" />
        <span className="align-middle"><FormattedMessage id="settings" /></span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem href="/" onClick={() => props.logoutUser()}>
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle"><FormattedMessage id="logout" /></span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    langDropdown: false,
  };

  handleLangDropdown = () =>
  this.setState({ langDropdown: !this.state.langDropdown })

  render() {
    let {
      username
    } = this.props

    return (
      <div>
        <ul className="nav navbar-nav navbar-nav-user float-right">
          <div className="">
            <Link to={"/pricing"}>
              <Button
                color="primary"
                className="btn-upgrade-navbar rounded px-1 mt-1 mr-1"
                outline
              >
                <span>
                  <FormattedMessage id="upgrade" />
                </span>
              </Button>
            </Link>
            <Link to={"/builder"}>
              <Button
                color="primary"
                className="btn-new-project rounded px-1 mt-1"
              >
                <Icon.Plus size={18} />
                <span>
                  <FormattedMessage id="newProject" />
                </span>
              </Button>
            </Link>
          </div>
          <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
            <DropdownToggle tag="a" className="nav-link dropdown-user-link">
              <span data-tour="user">
                <Avatar color="primary" className="mr-1" content={username !== null && username.charAt(0).toUpperCase()} />
              </span>
            </DropdownToggle>
            <UserDropdown {...this.props} />
          </UncontrolledDropdown> 
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    username: state.auth.login.user
  }
}

export default connect(mapStateToProps)(NavbarUser)

