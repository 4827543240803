const initialState = {
  error: false,
  message: null
};

export const common = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_ERROR": {
      return { ...state, error: action.error, message: action.message}
    }
    case "SHOW_MESSAGE": {
      return { ...state, message: action.message }
    }
    default: {
      return state
    }
  }
}