const initialState = {
  solutions: [],
  verticals: [],
  verticals_solutions: [],
  vertical: null,
  solution: null,
  error: null,
  loading: false,
};

export const solution = (state = initialState, action) => {
  switch (action.type) {
    case "GET_VERTICALS_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        verticals: action.payload,
      };
    case "GET_VERTICALS_SOLUTIONS_SUCCESS":
    case "GET_VERTICALS_SOLUTIONS_COPY_SUCCESS":
      return {
        ...state,
        loading: false,
        verticals_solutions: action.payload
      };
    case "GET_SOLUTIONS_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        solutions: action.payload,
      };
    case "GET_SOLUTION_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        solution: action.payload,
      };
    case "DELETE_SOLUTION_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        solutions: state.solutions.filter((m) => m.id !== state.solutionDelete),
        solutionDelete:null,
      };
    case "ADD_SOLUTION_SUCCESS":
    case "COPY_SOLUTION_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false
      };
    case "GET_VERTICALS":
    case "GET_SOLUTIONS":
    case "GET_SOLUTION":
    case "ADD_SOLUTION":
    case "EDIT_SOLUTION":
    case "COPY_SOLUTION":
      return {
        ...state,
        loading: action.payload,
      };
    case "DELETE_SOLUTION":
      return {
        ...state,
        loading: true,
        solutionDelete: action.payload,
      };
    case "GET_VERTICALS_SOLUTIONS":
    case "GET_VERTICALS_SOLUTIONS_COPY":
      return {
        ...state,
        loading: action.payload
      };
    case "EDIT_SOLUTION_SUCCESS":
      return {
        ...state,
        solutions: state.solutions.map((m) => m.id === action.payload.id ? m = action.payload : m ),
        loading: false,
      };
    case "GET_VERTICALS_ERROR":
    case "GET_SOLUTIONS_ERROR":
    case "GET_SOLUTION_ERROR":
    case "GET_VERTICALS_SOLUTION_ERROR":
    case "GET_VERTICALS_SOLUTIONS_COPY_ERROR":
    case "ADD_SOLUTION_ERROR":
    case "EDIT_SOLUTION_ERROR":
    case "DELETE_SOLUTION_ERROR":
    case "COPY_SOLUTION_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};
