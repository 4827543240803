const initialState = {
  lists: [],
  list: [],
  fields: [],
  listDetail: null,
  listNew: null,
  error: null,
  loading: true,
  loadingImport: false,
  changedList: false,
  sortIndex: [0, 0],
  totalRecords: 0,
  totalPages: 0
};

const getIndex = (arr3, perPage, currentPage, totalRecords) => {
  let startIndex = (perPage * (currentPage - 1)) + 1
  let endIndex = perPage * currentPage
  if (totalRecords < endIndex) {
    endIndex = totalRecords
  }
  let finalArr = [startIndex, endIndex]
  return (arr3 = finalArr)
}

export const list = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CONTACT_LISTS_PAGINATE":
      return { ...state, loading: action.payload, lists: [] };
    case "GET_LIST_DETAIL":
    case "GET_CONTACT_LIST":
    case "GET_FIELDS":
    case "ADD_LIST":
    case "EDIT_LIST":
    case "GET_LISTS_EVALUATOR_MODE":
      return { ...state, loading: action.payload
    };
    case "GET_LIST_SEGMENT":
      return { ...state, loading: action.payload, listNew: null
    };
    case "DELETE_LIST":
      return {
        ...state,
        loading: true,
        listDelete: action.payload
      };
    case "DELETE_FIELD_LIST":
      return {
        ...state,
        loading: true,
        fieldDelete: action.payload
      };
    case "ADD_FIELD_LIST":
    case "EDIT_FIELD_LIST":
      return { ...state, loading: false, changedList: false };
    case "ADD_CONTACTS_IMPORT":
      return { ...state, loadingImport: true, changedList: false };
    case "DELETE_SEGMENT":
      return {
        ...state,
        loading: true,
        segmentDelete: action.payload.id,
        listDelete: action.payload.list,
      };
    case "GET_LIST_SEGMENT_SUCCESS":
    case "GET_LISTS_EVALUATOR_MODE_SUCCESS":
      return {
        ...state,
        loading: false,
        lists: action.payload
      };
    case "DELETE_SEGMENT_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        lists: state.lists.map(l => l.id === state.listDelete ? {
          ...l,
          'segments': l.segments.filter(s => s.id !== state.segmentDelete)
        } : l
        ),
        segmentDelete: null,
      };
    case "ADD_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        lists: state.lists !== null ? [...state.lists, action.payload] : [action.payload],
        listNew: action.payload
      };
    case "EDIT_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        lists: state.lists.map(l => l.id === action.payload.id ? l = action.payload : l),
      };
    case "DELETE_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        lists: state.lists.filter(l => l.id !== state.listDelete),
        listDelete: null
      };
    case "DELETE_FIELD_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        fields: state.fields.filter(l => l.id !== state.fieldDelete),
        fieldDelete: null
      };
    case "GET_CONTACT_LIST_SUCCESS":
      return { ...state, loading: false, error: false, lists: action.payload };
    case "GET_LIST_DETAIL_SUCCESS":
      return { ...state, loading: false, error: false, listDetail: action.payload };
    case "GET_CONTACT_LISTS_PAGINATE_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        lists: action.payload.lists,
        totalRecords: action.payload.total_items_count,
        rowsPerPage: action.payload.rows_per_page,
        totalPages: action.payload.total_pages,
        currentPage: action.payload.current_page,
        sortIndex: getIndex(state.sortIndex, action.payload.rows_per_page, action.payload.current_page, action.payload.total_items_count)
      };
    case "GET_FIELDS_SUCCESS":
      return { ...state, loading: false, error: false, fields: action.payload };
    case "ADD_FIELD_LIST_SUCCESS":
    case "EDIT_FIELD_LIST_SUCCESS":
    case "ADD_CONTACTS_IMPORT_SUCCESS":
      return { ...state, loadingImport: false, changedList: true };
    case "GET_CONTACT_LIST_ERROR":
    case "GET_CONTACT_LISTS_PAGINATE_ERROR":
    case "GET_FIELDS_ERROR":
    case "ADD_LIST_ERROR":
    case "EDIT_LIST_ERROR":
    case "DELETE_LIST_ERROR":
    case "DELETE_SEGMENT_ERROR":
    case "GET_LIST_DETAIL_ERROR":
    case "ADD_FIELD_LIST_ERROR":
    case "EDIT_FIELD_LIST_ERROR":
    case "DELETE_FIELD_LIST_ERROR":
    case "GET_LIST_SEGMENT_ERROR":
    case "GET_LISTS_EVALUATOR_MODE_ERROR":
      return { ...state, loading: false, error: action.payload };
    case "ADD_CONTACTS_IMPORT_ERROR":
      return { ...state, loadingImport: false, error: action.payload };
    default:
      return { ...state };
  }
};

