import axios from "../../../Api"
import { history } from "../../../history"

export const getLanguagesAvailablePaginate = (id, currentPage, rowsPerPage) => {
  return async dispatch => {
    dispatch({
      type: "GET_LANGUAGES_SURVEY",
      payload: true
    });

    try {
      const response = await axios.get(`/api/survey/${id}/translations/${currentPage}/${rowsPerPage}`);
      dispatch({
        type: "GET_LANGUAGES_SURVEY_SUCCESS",
        payload: response.data.data
      })
    } catch (error) {
      dispatch({
        type: "GET_LANGUAGES_SURVEY_ERROR",
        payload: true
      })
    }
  }
}

export const getLanguagesAvailable = (id) => {
  return async dispatch => {
    dispatch({
      type: "GET_LANGUAGES_SURVEY",
      payload: true
    });

    try {
      const response = await axios.get(`/api/survey/${id}/translations`);
      dispatch({
        type: "GET_LANGUAGES_SURVEY_SUCCESS",
        payload: response.data.data
      })
    } catch (error) {
      dispatch({
        type: "GET_LANGUAGES_SURVEY_ERROR",
        payload: true
      })
    }
  }
}

export const getLanguages = () => {
  return async dispatch => {
    dispatch({
      type: "GET_LANGUAGES",
      payload: true
    });

    try {
      const response = await axios.get("/api/languages");
      dispatch({
        type: "GET_LANGUAGES_SUCCESS",
        payload: response.data.data
      })
    } catch (error) {
      dispatch({
        type: "GET_LANGUAGES_ERROR",
        payload: true
      })
    }
  }
}

export const getAllQuestions = (survey, language_id, currentPage, rowsPerPage, searchText) => {
  return async dispatch => {
    dispatch({
      type: "GET_ALL_QUESTIONS",
      payload: true
    });

    try {
      const response = await axios.get(`/api/questions/${survey}/${language_id}/${currentPage}/${rowsPerPage}/${searchText}`);
      dispatch({
        type: "GET_ALL_QUESTIONS_SUCCESS",
        payload: response.data.data
      })
    } catch (error) {
      dispatch({
        type: "GET_ALL_QUESTIONS_ERROR",
        payload: true
      })
    }
  }
}

export const addTranslate = (id, language_id) => {
  return async dispatch => {
    dispatch({
      type: "ADD_LANGUAGE",
      payload: true
    });

    try {
      const response = await axios.post(`/api/survey/${id}/translate/${language_id}`);
      dispatch({
        type: "ADD_LANGUAGE_SUCCESS",
        payload: response.data.data
      })
      history.push({
        pathname: "/survey/translate",
        state: { survey: id, language_id: language_id }
      })
    } catch (error) {
      dispatch({
        type: "ADD_LANGUAGE_ERROR",
        payload: true
      })
    }
  }
}

export const editTranslate = (id, data) => {
  const params = new URLSearchParams(data);

  return async dispatch => {
    dispatch({
      type: "EDIT_TRANSLATE",
      payload: true
    });

    try {
      const response = await axios.put(`/api/survey/${id}/translate`, params);
      dispatch({
        type: "EDIT_TRANSLATE_SUCCESS",
        payload: response.data.data
      })
    } catch (error) {
      dispatch({
        type: "EDIT_TRANSLATE_ERROR",
        payload: true
      })
    }
  }
}

export const deleteTranslate = (id, language_id) => {
  return async dispatch => {
    dispatch({
      type: "DELETE_LANGUAGE",
      payload: language_id
    });

    try {
      const response = await axios.delete(`/api/survey/${id}/translate/${language_id}`);
      dispatch({
        type: "DELETE_LANGUAGE_SUCCESS",
        payload: response.data.data
      })
    } catch (error) {
      dispatch({
        type: "DELETE_LANGUAGE_ERROR",
        payload: true
      })
    }
  }
}

export const getTranslate = (id, language_id) => {
  return async dispatch => {
    dispatch({
      type: "GET_TRANSLATE",
      payload: true
    });

    try {
      const response = await axios.get(`/api/survey/${id}/translate/${language_id}`);
      dispatch({
        type: "GET_TRANSLATE_SUCCESS",
        payload: response.data.data
      })
    } catch (error) {
      dispatch({
        type: "GET_TRANSLATE_ERROR",
        payload: true
      })
    }
  }
}