import { combineReducers } from "redux"
import { survey } from "./surveysListReducers"
import { surveyShow } from "./surveyShowReducers"
import { surveyTranslate } from "./surveyTranslateReducers"

const surveysReducers = combineReducers({
    survey,
    surveyShow,
    surveyTranslate
})

export default surveysReducers
