import React from "react";
import {
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import * as Icon from "react-feather";

import { history } from "../../../history";
import { connect } from "react-redux";
import {
  loadSuggestions,
  updateStarred,
} from "../../../redux/actions/navbar/Index";
import Tour, { STATUS } from "react-joyride"
import "../../../assets/scss/plugins/extensions/react-tour.scss"
// a little function to help us with reordering the bookmarks
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

class NavbarBookmarks extends React.PureComponent {
  state = {
    showBookmarks: false,
    value: "",
    noSuggestions: false,
    isStarred: false,
    suggestions: [],
    starredItems: [],
    isTouropen: false
  };

  updateBookmarks = false;

  startTour = () => {
    this.setState({
      isTouropen: true
    })
  }


  handleBookmarksVisibility = () => {
    this.setState({
      showBookmarks: !this.state.showBookmarks,
      value: "",
      suggestions: [],
      noSuggestions: false,
      starred: null,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.bookmarks.starred.length !== this.state.starredItems.length &&
      this.updateBookmarks === true
    ) {
      this.setState({ starredItems: this.props.bookmarks.starred });
      this.updateBookmarks = false;
    }
  }

  componentDidMount() {
    let {
      bookmarks: { suggestions, starred },
      loadSuggestions,
    } = this.props;
    this.setState(
      {
        suggestions: suggestions,
        starredItems: starred,
      },
      loadSuggestions()
    );
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const starredItems = reorder(
      this.state.starredItems,
      result.source.index,
      result.destination.index
    );

    this.setState({
      starredItems,
    });
  };

  helpDropdown = (props) => {
    return (
      <DropdownMenu right>
        <DropdownItem tag="a" onClick={(e) => history.push("/profile")}>
          <Icon.Film size={14} className="mr-50" />
          <span className="align-middle">Tour de allswers</span>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem tag="a" onClick={(e) => history.push("/faq")}>
          <Icon.HelpCircle size={14} className="mr-50" />
          <span className="align-middle">Ir a f.a.q.</span>
        </DropdownItem>
      </DropdownMenu>
    );
  };

  render() {
    let {
      bookmarks: { extraStarred },
      sidebarVisibility
    } = this.props;
    const steps = [
      {
        target: ".menu-item-model",
        content: "Aquí tienes todos los modelos que hemos diseñado para ti y tu empresa. Están agrupados en las distintas verticales Recursos Humanos, Calidad y Marketing.",
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: ".menu-item-projects",
        content: "En esta sección encontrarás todos los proyectos que has deplegado, además podrás ver las respuestas y estadísticas de cada proyecto. Para crear un proyectos debes haber creado una encuesta y una lista de contactos como mínimo",
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: ".menu-item-surveys",
        content: "Aquí crearás todas las encuestas, es necesario que previamente hayas creado modelos para implementarlos.",
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: ".menu-item-contacts",
        content: "Entra aquí para dar de alta nuevas listas de contactos y segmentarlos.",
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: ".menu-item-setup",
        content: "Edita tus datos generales, tus datos de suscripción y otros.",
        disableBeacon: true,
        placement: 'right',
      },
      {
        target: ".menu-item-faq",
        content: "Si quieres volver a ver este tour o cualquier otra duda, entra aquí.",
        disableBeacon: true,
        placement: 'bottom',
      },
      {
        target: ".help-navbar",
        content: "O haz click aquí. Recuerda, lo que no se mide no se puede mejorar",
        disableBeacon: true,
        placement: 'bottom',
      },
    ];

    const renderExtraStarred =
      extraStarred.length > 0
        ? extraStarred.map((i) => {
          const IconTag = Icon[i.icon ? i.icon : null];
          return (
            <DropdownItem key={i.id} href={i.link}>
              <IconTag size={15} />
              <span className="align-middle ml-1">{i.title}</span>
            </DropdownItem>
          );
        })
        : null;

    return (

      <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
        <ul className="navbar-nav d-xl-none">
          <NavItem className="mobile-menu mr-auto">
            <NavLink
              className="nav-menu-main menu-toggle hidden-xs is-active"
              onClick={sidebarVisibility}
            >
              <Icon.Menu className="ficon" />
            </NavLink>
          </NavItem>
        </ul>
        <ul className="nav navbar-nav bookmark-icons">

          <NavItem
            className="nav-item d-none d-lg-block"
            onClick={this.handleBookmarksVisibility}
          >
            <UncontrolledDropdown className="dropdown-user nav-item">
              <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                <Icon.HelpCircle className="text-secondary help-navbar" size={21} />
              </DropdownToggle>
              <DropdownMenu direction="left">
                <DropdownItem tag="a" onClick={this.startTour}>
                  <Icon.Film size={14} className="mr-50" />
                  <span className="align-middle">Tour de allswersss</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem tag="a" href="https://help.allswers.com">
                  <Icon.HelpCircle size={14} className="mr-50" />
                  <span className="align-middle">Ir a f.a.q.</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </NavItem>

        </ul>
        <Tour
          disableOverlay
          steps={steps}
          run={this.state.isTouropen}
          continuous={true}
          showSkipButton={true}
          floaterProps={{ disableAnimation: true }}
          callback={data => {
            if ([STATUS.FINISHED, STATUS.SKIPPED].includes(data.status)) {
              this.setState({ isTouropen: false })
            }
          }}
          styles={{
            options: {
              zIndex: 10000

            }
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    bookmarks: state.navbar,
  };
};

export default connect(mapStateToProps, { loadSuggestions, updateStarred })(
  NavbarBookmarks
);
