import jwtDecode from 'jwt-decode';
import axios from "axios"
import { getUser } from '../profile';

export const changeRole = role => {
  return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}

export const loginWithJWT = (username, password) => {

  return async dispatch => {
    var bodyFormData = new FormData();
    bodyFormData.set('_username', username);
    bodyFormData.set('_password', password);

    const responseLogin = await axios.post(`${process.env.REACT_APP_WEB}/api/login_check`, bodyFormData)
      .then(response => {

        if (response.data) {
          localStorage.setItem("token", response.data.token);
          axios.defaults.headers.common['token'] = "Bearer " + response.data.token;
          const decoded = jwtDecode(response.data.token);

          let loggedInUser = decoded
          localStorage.setItem("identity", loggedInUser.username);

          let role = loggedInUser.roles.find(role => role !== "ROLE_USER")

          dispatch(getUser())

          dispatch({
            type: "LOGIN_WITH_JWT",
            payload: { token: response.data.token, user: loggedInUser.username, role: role }
          })

          // history.push("/projects")
        } else {
          dispatch({ type: "FETCH_ERROR", error: true, message: response.data.message });
        }
      })
      .catch(error => {
        dispatch({ type: "FETCH_ERROR", error: true, message: error.message });
      })
  }
}

export const generateToken = (username, password) => {

  return async dispatch => {
    var bodyFormData = new FormData();
    bodyFormData.set('_username', username);
    bodyFormData.set('_password', password);
    bodyFormData.set('exp', 'api');

    dispatch({
      type: "GENERATE_TOKEN",
      payload: true
    })

    const responseLogin = await axios.post(`${process.env.REACT_APP_WEB}/api/login_check`, bodyFormData)
      .then(response => {

        if (response.data) {
          dispatch({
            type: "GENERATE_TOKEN_SUCCESS",
            payload: { token: response.data.token, error: false }
          })

        } else {
          dispatch({ type: "GENERATE_TOKEN_ERROR", error: true, message: response.data.message });
        }
      })
      .catch(error => {
        dispatch({ type: "GENERATE_TOKEN_ERROR", payload: true });
      })
  }
}

export const logoutUser = () => {
  return dispatch => {
    dispatch({ type: "LOGOUT" })

    localStorage.removeItem("token")
    localStorage.removeItem("identity")
    localStorage.removeItem("lang")
  }
}