const initialState = {
  languagesAll: [],
  languages: [],
  language: null,
  error: null,
  loading: false,
  sortIndex: [0, 0],
  totalRecords: 0,
  textTranslations: null,
  questions: []
};

const getIndex = (arr3, perPage, currentPage, totalRecords) => {
  let startIndex = (perPage * (currentPage - 1)) + 1
  let endIndex = perPage * currentPage
  if (totalRecords < endIndex) {
    endIndex = totalRecords
  }
  let finalArr = [startIndex, endIndex]
  return (arr3 = finalArr)
}

export const surveyTranslate = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LANGUAGES":
    case "GET_TRANSLATE":
    case "ADD_LANGUAGE":
    case "EDIT_TRANSLATE":
      return {
        ...state, loading: action.payload
      };
    case "GET_LANGUAGES_SURVEY":
      return {
        ...state,
        loading: action.payload,
        languages: [],
        sortIndex: [0, 0],
        totalRecords: 0
      };
    case "DELETE_LANGUAGE":
      return {
        ...state,
        loading: false,
        languageDelete: action.payload
      };
    case "GET_ALL_QUESTIONS":
      return { ...state, loading: action.payload, questions: [] };
    case "GET_TRANSLATE_SUCCESS":
      return {
        ...state, textTranslations: action.payload, loading: false
      };
    case "GET_LANGUAGES_SUCCESS":
      return {
        ...state, loading: false, languagesAll: action.payload
      };
    case "ADD_LANGUAGE_SUCCESS":
    case "EDIT_TRANSLATE_SUCCESS":
      return {
        ...state,
        loading: false,
        questions: state.questions.map(c => c.id === action.payload.id ? {...c, textTranslations: action.payload.textTranslations} : c ),
      };
    case "DELETE_LANGUAGE_SUCCESS":
      return {
        ...state,
        loading: false,
        languages: state.languages.filter(l => l.id !== state.languageDelete),
        languageDelete: null
      };
    case "GET_LANGUAGES_SURVEY_SUCCESS":
      return {
        ...state,
        loading: false,
        languages: action.payload !== null ? action.payload.languages : [],
        totalRecords: action.payload !== null ? action.payload.total_items_count : 0,
        rowsPerPage: action.payload !== null ? action.payload.rows_per_page : 5,
        totalPages: action.payload !== null ? action.payload.total_pages : 0,
        currentPage: action.payload !== null ? action.payload.current_page : 1,
        sortIndex: action.payload !== null ? getIndex(state.sortIndex, action.payload.rows_per_page, action.payload.current_page, action.payload.total_items_count) : [0, 0]
      };
    case "GET_ALL_QUESTIONS_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        questions: action.payload.questions,
        totalRecords: action.payload.total_items_count,
        rowsPerPage: action.payload.rows_per_page,
        totalPages: action.payload.total_pages,
        currentPage: action.payload.current_page,
        sortIndex: getIndex(state.sortIndex, action.payload.rows_per_page, action.payload.current_page, action.payload.total_items_count)
      };
    case "GET_LANGUAGES_SURVEY_ERROR":
    case "GET_LANGUAGES_ERROR":
    case "GET_LANGUAGE_ERROR":
    case "ADD_LANGUAGE_ERROR":
    case "EDIT_TRANSLATE_ERROR":
    case "DELETE_LANGUAGE_ERROR":
    case "GET_ALL_QUESTIONS_ERROR":
      return {
        ...state, loading: false, error: action.payload
      };
    default:
      return { ...state };
  }
};