const initialState = {
  objects: [],
  questions: [],
  indicators: [],
  error: null,
  loading: true
};

export const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case "GET_OBJECTS_DASHBOARD":
    case "ADD_OBJECT":
    case "EDIT_OBJECT":
    case "GET_QUESTIONS_DASHBOARD":
    case "GET_INDICATORS_DASHBOARD":
    case "EDIT_OBJECTS_DASHBOARD":
      return {
        ...state, loading: action.payload
      };
    case "DELETE_OBJECT":
      return {
        ...state,
        loading: true,
        objectDelete: action.payload,
      };
    case "GET_OBJECTS_DASHBOARD_SUCCESS":
    case "EDIT_OBJECTS_DASHBOARD_SUCCESS":
      return {
        ...state,
        loading: false,
        objects: action.payload
      };
    case "ADD_OBJECT_SUCCESS":
      return {
        ...state,
        loading: false,
        objects: state.objects.length > 0 ? [...state.objects, action.payload] : [action.payload],
      };
    case "EDIT_OBJECT_SUCCESS":
      return {
        ...state,
        loading: false,
        objects: action.payload
      };
    case "DELETE_OBJECT_SUCCESS":
      return {
        ...state,
        loading: false,
        objects: state.objects.filter((m) => m.id !== state.objectDelete),
        objectDelete: null
      };
    case "GET_QUESTIONS_DASHBOARD_SUCCESS":
      return {
        ...state,
        loading: false,
        questions: action.payload
      };
    case "GET_INDICATORS_DASHBOARD_SUCCESS":
      return {
        ...state,
        loading: false,
        indicators: action.payload
      };
    case "GET_OBJECTS_DASHBOARD_ERROR":
    case "ADD_OBJECT_ERROR":
    case "EDIT_OBJECTS_DASHBOARD_ERROR":
    case "EDIT_OBJECT_ERROR":
    case "GET_INDICATORS_DASHBOARD_ERROR":
    case "DELETE_OBJECT_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return { ...state };
  }
};

