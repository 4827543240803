import { combineReducers } from "redux"
import { company } from "./companyReducers"
import { server } from "./serverReducers"

const setupReducers = combineReducers({
    company,
    server
})

export default setupReducers
