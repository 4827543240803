import axios from "../../../Api"
import { history } from "../../../history"

export const getUserProfile = () => {
  return async dispatch => {
    dispatch({
      type: "GET_USER",
      payload: true
    });

    try {
      const response = await axios.get("/api/user")
      localStorage.setItem("lang", response.data.data.language_code);
      dispatch({
        type: "GET_USER_SUCCESS",
        payload: response.data.data
      })
    } catch (error) {
      dispatch({
        type: "GET_USER_ERROR",
        payload: true
      })
    }
  }
}

export const getUser = () => {
  return async dispatch => {
    dispatch({
      type: "GET_USER",
      payload: true
    });

    try {
      const response = await axios.get("/api/user")
      localStorage.setItem("lang", response.data.data.language_code);
      dispatch({
        type: "GET_USER_SUCCESS",
        payload: response.data.data
      })

      let userData = response.data.data

      if (userData.last_login !== userData.dateAdded) {
        history.push("/projects")
      } else {
        history.push("/builder/welcome")
      }

    } catch (error) {
      dispatch({
        type: "GET_USER_ERROR",
        payload: true
      })
    }
  }
}

export const editUser = user => {
  const params = new URLSearchParams(user);

  return async dispatch => {
    dispatch({
      type: "EDIT_USER_ONE",
      payload: true
    })

    try {
      const response = await axios.put(`/api/user/${user.id}`, params)
      dispatch({
        type: "EDIT_USER_ONE_SUCCESS",
        payload: response.data.data
      })
    } catch (error) {
      dispatch({
        type: "EDIT_USER_ONE_ERROR",
        payload: true
      })
    }
  }
}

export const editPassword = user => {
  const params = new URLSearchParams(user);

  return async dispatch => {
    dispatch({
      type: "CHANGE_PASSWORD",
      payload: true
    })

    try {
      const response = await axios.post("/api/change/credentials", params)
      dispatch({
        type: "CHANGE_PASSWORD_SUCCESS",
        payload: response.data.data
      })
    } catch (error) {
      dispatch({
        type: "CHANGE_PASSWORD_ERROR",
        payload: error.response.data.msg
      })
    }
  }
}