import React, { useState, useEffect, Fragment } from "react";
import { IntlProvider } from "react-intl"
import { useSelector } from "react-redux";

import messages_en from "../../assets/data/locales/en.json"
import messages_es from "../../assets/data/locales/es.json"
import messages_fr from "../../assets/data/locales/fr.json"
import messages_pt from "../../assets/data/locales/pt.json"

const menu_messages = {
  en: messages_en,
  es: messages_es,
  fr: messages_fr,
  pt: messages_pt
}

const Context = React.createContext()

const IntlProviderWrapper = (props) => {

  const [locale, setLocale] = useState("es");
  const [messages, setMessages] = useState(menu_messages["es"]);

  const lang = localStorage.getItem('lang')

  const currentLanguage = useSelector((state) => {
    if (state.profile.user !== null) {
      return state.profile.user.language_code
    }
  } )

  useEffect(() => {
    if (lang) {
        setLocale(lang)
        setMessages(menu_messages[lang])
    }else {
      setLocale(currentLanguage)
      setMessages(menu_messages[currentLanguage])
    }
  }, [currentLanguage])

  const selectLanguage = (newLang) => {
    setLocale(newLang);
    setMessages(menu_messages[newLang])
  }

  return (
    <Context.Provider
      value={{
        locale, selectLanguage
      }}
    >
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
      >
        {props.children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
