const initialState = {
  indicators: [],
  indicator: null,
  error: false,
  loading: false,
  sortIndex: [0, 0],
  totalRecords: 0
};

const getIndex = (arr3, perPage, currentPage, totalRecords) => {
  let startIndex = (perPage * (currentPage - 1)) + 1
  let endIndex = perPage * currentPage
  if (totalRecords < endIndex) {
    endIndex = totalRecords
  }
  let finalArr = [startIndex, endIndex]
  return (arr3 = finalArr)
}


export const indicator = (state = initialState, action) => {
  switch (action.type) {
    case "GET_INDICATORS_PAGINATE":
      return {
        ...state,
        loading: action.payload,
        indicators: [],
        sortIndex: [0, 0],
        totalRecords: 0
      };
    case "GET_INDICATOR":
    case "GET_INDICATORS":
      return {
        ...state,
        loading: action.payload,
        indicators: []
      };
    case "ADD_INDICATOR":
    case "EDIT_INDICATOR":
      return {
        ...state,
        loading: action.payload
      };

    case "GET_INDICATORS_PAGINATE_SUCCESS":
      return {
        ...state,
        loading: false,
        indicators: action.payload.indicators,
        totalRecords: action.payload.total_items_count,
        rowsPerPage: action.payload.rows_per_page,
        totalPages: action.payload.total_pages,
        currentPage: action.payload.current_page,
        sortIndex: getIndex(state.sortIndex, action.payload.rows_per_page, action.payload.current_page, action.payload.total_items_count)
      };
    case "GET_INDICATORS_SUCCESS":
      return {
        ...state,
        loading: false,
        indicators: action.payload ? action.payload : [],
      };
    case "GET_INDICATOR_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        indicator: action.payload,
      };
    case "ADD_INDICATOR_SUCCESS":
      return {
        ...state,
        loading: false,
        indicators: state.indicators !== null ? [...state.indicators, action.payload] : [action.payload]
      };
    case "EDIT_INDICATOR_SUCCESS":
      return {
        ...state,
        indicators: state.indicators.map((m) => m.id === action.payload.id ? m = action.payload : m),
        loading: false,
      };
    case "GET_INDICATORS_PAGINATE_ERROR":
    case "GET_INDICATORS_ERROR":
    case "GET_INDICATOR_ERROR":
    case "ADD_INDICATOR_ERROR":
    case "EDIT_INDICATOR_ERROR":
    case "DELETE_INDICATOR_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "DELETE_INDICATOR":
      return {
        ...state,
        loading: true,
        indicatorDelete: action.payload
      };
    case "DELETE_INDICATOR_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        indicators: state.indicators.filter((i) => i.id !== state.indicatorDelete),
        indicatorDelete: null
      };
    default:
      return { ...state };
  }
};

