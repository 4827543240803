const initialState = {
  servers: [],
  error: null,
  loading: false
};

export const server = (state = initialState, action) => {
  switch (action.type) {
    case "GET_WAVES_SERVER":
      return { ...state, waves: action.payload };
    case "GET_SERVERS":
    case "ADD_SERVER":
    case "EDIT_SERVER":
    case "ACTIVE_SERVER":
      return { ...state, loading: action.payload };
    case "DELETE_SERVER":
      return {
        ...state,
        loading: true,
        serverDelete: action.payload
      };
    case "ACTIVE_SERVER_SUCCESS":
      return {
        ...state,
        loading: false,
        servers: state.servers.map(s => s.id === action.payload ? {...s, 'active' : true} : s )
      };
    case "GET_SERVERS_SUCCESS":
      return { ...state, loading: false, servers: action.payload };
    case "ADD_SERVER_SUCCESS":
      return {
        ...state, loading: false, servers: [...state.servers, action.payload]
      };
    case "EDIT_SERVER_SUCCESS":
      return {
        ...state,
        loading: false,
        servers: state.servers.map(s => s.id === action.payload.id ? s = action.payload : s ),
      };
    case "DELETE_SERVER_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        servers: state.servers.filter(c => c.id !== state.serverDelete),
        serverDelete:null
      };
    case "GET_SERVERS_ERROR":
    case "ADD_SERVER_ERROR":
    case "EDIT_SERVER_ERROR":
    case "ACTIVE_SERVER_ERROR":
    case "DELETE_SERVER_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return { ...state };
  }
};