import axios from "../Api"
import LocalStorageService from "./localStorageService";
import { history } from "../history"

const localStorageService = LocalStorageService.getService();

export function initAxiosInterceptors() {
    axios.interceptors.request.use(
    config => {
        const token = localStorageService.getAccessToken();
        if (token) {
            config.headers.Authorization = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

    
    axios.interceptors.response.use(response => {
        return response;
     }, error => {
       if (error.response.status === 401) {
        localStorage.removeItem("token")
        localStorage.removeItem("identity")
        history.push("/")
        Promise.reject(error)
       } 
       return error;
     });

}

