const initialState = {
  survey: null,
  error: null,
  loading: false,
  gratitude: null
};

export const surveyShow = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SURVEY_ANONYMOUS_SUCCESS":
    case "GET_SURVEY_SHOW_SUCCESS":
    case "GET_SURVEY_BOOKGY_SUCCESS":
    case "GET_SURVEY_PREVIEW_SUCCESS":
      return {
        ...state, loading: false, error: false, survey: action.payload
      };
    case "SAVE_ANONYMOUS_SUCCESS":
    case "SAVE_SURVEY_SUCCESS":
      return {
        ...state, loading: false, error: false, gratitude: action.payload
      };
    case "SAVE_RATING_SUCCESS":
      return {
        ...state, loading: false, error: false
      };
    case "GET_SURVEY_ANONYMOUS":
    case "GET_SURVEY_SHOW":
    case "GET_SURVEY_BOOKGY":
    case "GET_SURVEY_PREVIEW":
      return {
        ...state, loading: action.payload, survey: null
      };
    case "SAVE_ANONYMOUS":
    case "SAVE_SURVEY":
      return {
        ...state, loading: action.payload
      };
    case "SAVE_RATING":
      return {
        ...state, loading: action.payload
      };
    case "GET_SURVEY_ANONYMOUS_ERROR":
    case "GET_SURVEY_SHOW_ERROR":
    case "GET_SURVEY_BOOKGY_ERROR":
    case "SAVE_ANONYMOUS_ERROR":
    case "SAVE_RATING_ERROR":
    case "SAVE_SURVEY_ERROR":
      return {
        ...state, loading: false, error: action.payload
      };
    default:
      return { ...state };
  }
};

