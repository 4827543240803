import React, { Suspense, lazy } from "react"
import { Router, Switch, Route } from "react-router-dom"
import { history } from "./history"
import { connect } from "react-redux"
import Spinner from "./components/@vuexy/spinner/Loading-spinner"
import { ContextLayout } from "./utility/context/Layout"
import TranslateQuestionList from "./views/pages/translations/edit/TranslateQuestionList"
// import jwtDecode from 'jwt-decode';

// Route-based code splitting

const Login = lazy(() =>
  import("./views/pages/authentication/login/Login")
)

const LoginEs = lazy(() =>
  import("./views/pages/authentication/login/LoginEs")
)

const Signup = lazy(() =>
  import("./views/pages/authentication/signup/SignUp")
)

const SignupEs = lazy(() =>
  import("./views/pages/authentication/signup/SignUpEs")
)

const SignupAppSumo = lazy(() =>
  import("./views/pages/authentication/signupAppSumo/SignUpAppSumo")
)

/*--  Dashboard  --*/
const Dashboard = lazy(() =>
  import("./views/pages/home/Dashboard")
)

const Home = lazy(() =>
  import("./views/pages/home/Home")
)

const SuscriptorList = lazy(() =>
  import("./views/pages/projects/waves/viewAnswers/SuscriptorList")
)

const ViewAnswers = lazy(() =>
  import("./views/pages/projects/waves/viewAnswers/ViewAnswers")
)

const SuscriptorListFill = lazy(() =>
  import("./views/pages/projects/waves/fillAnswers/SuscriptorList")
)

const NewProject = lazy(() =>
  import("./views/pages/projects/builder/NewProject")
)

const Steps = lazy(() =>
  import("./views/pages/projects/builder/Steps")
)

/*--  Proyectos  --*/
const ProjectsList = lazy(() =>
  import("./views/pages/projects/ProjectList")
)

/*--  Encuestas  --*/

const TranslateList = lazy(() =>
  import("./views/pages/translations/TranslateList")
)

const SurveyShow = lazy(() =>
  import("./views/pages/surveys/surveySave/SurveyShow")
)

const GratitudeView = lazy(() =>
  import("./views/pages/surveys/surveySave/GratitudeView")
)

const IntroductionView = lazy(() =>
  import("./views/pages/surveys/surveySave/IntroductionView")
)

/*--  Contactos  --*/
const ContactList = lazy(() =>
  import("./views/pages/contacts/contactList/ContactList")
)

const Contacts = lazy(() =>
  import("./views/pages/contacts/contact/ContactsView")
)

const StepsImportContacts = lazy(() =>
  import("./views/pages/contacts/contactImport/StepsImport")
)

/*--  Soluciones  --*/
const CustomSolutionList = lazy(() =>
  import("./views/pages/solutions/solution/SolutionList")
)

const Solution = lazy(() =>
  import("./views/pages/solutions/builderSolution/SolutionSteps")
)

/*--  Segments  --*/
const Segment = lazy(() =>
  import("./views/pages/contacts/segmentList/SegmentContacts")
)

/*--  Messages Templates  --*/
const MessagesTemplates = lazy(() =>
  import("./views/pages/templates/MessageTemplateList")
)

const MessagesTemplateTranslations = lazy(() =>
  import("./views/pages/templates/MessageTemplateTranslationList")
)

/*--  Perfil  --*/
const Profile = lazy(() =>
  import("./views/pages/profile/Profile")
)
/*--  FAQ  --*/
const Faq = lazy(() =>
  import("./views/pages/faq/Faq")
)

/*--  Configuración  --*/
const Settings = lazy(() =>
  import("./views/pages/setup/Settings")
)

const Pricing = lazy(() =>
  import("./views/pages/package/Pricing")
)

const Error = lazy(() =>
  import("./views/pages/error/Error")
)

// Set Layout and Component Using App Route
const RouteConfig = ({
  component: Component,
  fullLayout,
  surveyLayout,
  navbarLayout,
  permission,
  user,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      return (
        <ContextLayout.Consumer>
          {context => {
            let LayoutTag =
              fullLayout === true ? context.fullLayout
                : surveyLayout === true ? context.surveyLayout
                  : navbarLayout === true ? context.navbarLayout
                    : context.VerticalLayout
            return (
              <LayoutTag {...props} permission={user}>
                <Suspense fallback={<Spinner />}>
                  <Component {...props} />
                </Suspense>
              </LayoutTag>
            )
          }}
        </ContextLayout.Consumer>
      )
    }}
  />
)

const mapStateToProps = state => {
  return {
    user: state.auth.login.userRole
  }
}

const AppRoute = connect(mapStateToProps)(RouteConfig)

class AppRouter extends React.Component {
  render() {
    return (
      // Set the directory path if you are deploying in sub-folder
      <Router history={history}>
        <Switch>
          <AppRoute
            exact
            path="/home"
            component={Home}
          />
          <AppRoute
            exact
            path="/dashboard"
            component={Dashboard}
          />
          <AppRoute
            exact
            path="/solutions"
            component={CustomSolutionList}
          />
          <AppRoute
            exact
            path="/solution/:id/:survey"
            component={Solution}
          />
          <AppRoute
            exact
            path="/project/wave/answers"
            component={SuscriptorList}
          />
          <AppRoute
            exact
            path="/project/wave/:id/fill/:list"
            component={SuscriptorListFill}
          />
          <AppRoute
            exact
            path="/wave/target/edit/:id/:language"
            component={ViewAnswers}
            surveyLayout
          />
          <AppRoute
            exact
            path="/wave/target/:id/:language"
            component={ViewAnswers}
            surveyLayout
          />
          <AppRoute
            exact
            path="/projects"
            component={ProjectsList}
          />
          <AppRoute
            exact
            path="/builder/steps/:project/:survey/:step"
            component={Steps}
          />
          <AppRoute
            exact
            path="/builder"
            component={NewProject}
          />
          <AppRoute
            exact
            path="/builder/welcome"
            component={NewProject}
          />
          <AppRoute
            exact
            path="/survey/translations"
            component={TranslateList}
          />
          <AppRoute
            exact
            path="/survey/translate"
            component={TranslateQuestionList}
          />
          <AppRoute
            exact
            path="/survey/:type/:id/:language"
            component={SurveyShow}
            surveyLayout
          />
          <AppRoute
            exact
            path="/survey/:type/:id/:language/:question/:answer"
            component={SurveyShow}
            surveyLayout
          />
          <AppRoute
            exact
            path="/survey/:type/:wave_id/:contact_list_id/:bookgy_id/:vehicle_id/:booking_id"
            component={SurveyShow}
            surveyLayout
          />
          <AppRoute
            exact
            path="/survey/sent/:type/:wave_id/:contact_list_id/:bookgy_id/:vehicle_id/:booking_id"
            component={GratitudeView}
            surveyLayout
          />
          <AppRoute
            exact
            path="/contacts/import/:id"
            component={StepsImportContacts}
          />
          <AppRoute
            exact
            path="/contactList"
            component={ContactList}
          />
          <AppRoute
            exact
            path="/contacts/:id"
            component={Contacts}
          />
          <AppRoute
            exact
            path="/segment"
            component={Segment}
          />
          <AppRoute
            exact
            path="/templates"
            component={MessagesTemplates}
          />
          <AppRoute
            exact
            path="/templates/translations/:id"
            component={MessagesTemplateTranslations}
          />
          <AppRoute
            exact
            path="/profile"
            component={Profile}
          />
          <AppRoute
            exact
            path="/faq"
            component={Faq}
          />
          <AppRoute
            exact
            path="/settings"
            component={Settings}
          />
          <AppRoute
            exact
            path="/pricing"
            component={Pricing}
            navbarLayout
          />
          <AppRoute
            exact
            path="/signup"
            component={Signup}
            fullLayout
          />
          <AppRoute
            exact
            path="/signup/es"
            component={SignupEs}
            fullLayout
          />
          <AppRoute
            exact
            path="/appsumo"
            component={SignupAppSumo}
            fullLayout
          />
          <AppRoute
            exact
            path="/"
            component={Login}
            fullLayout
          />
          <AppRoute
            exact
            path="/login/es"
            component={LoginEs}
            fullLayout
          />
          <AppRoute
            path="*"
            component={Error}
          />
        </Switch>
      </Router>
    )
  }
}

export default AppRouter
