const initialState = {
  user: null,
  loading: false,
  messageError: null,
  messageSuccess: null
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USER":
    case "EDIT_USER_ONE":
    case "CHANGE_PASSWORD":
      return { ...state, messageError: null, messageSuccess: null, loading: action.payload };
    case "GET_USER_SUCCESS":
    case "EDIT_USER_ONE_SUCCESS":
      return { ...state, loading: false, user: action.payload };
    case "CHANGE_PASSWORD_SUCCESS":
      return { ...state, loading: false, messageSuccess: action.payload};
    case "GET_USER_ERROR":
    case "EDIT_USER_ONE_ERROR":
    case "CHANGE_PASSWORD_ERROR":
      return { ...state, loading: false, messageError: action.payload };
    default:
      return { ...state };
  }
};

export default profile;