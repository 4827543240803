const initialState = {
  surveys: [],
  questions: [],
  survey: null,
  error: null,
  loading: false,
  surveyUpdated: true,
  sortIndex: [0, 0],
  totalRecords: 0
};

const getIndex = (arr3, perPage, currentPage, totalRecords) => {
  let startIndex = (perPage * (currentPage - 1)) + 1
  let endIndex = perPage * currentPage
  if (totalRecords < endIndex) {
    endIndex = totalRecords
  }
  let finalArr = [startIndex, endIndex]
  return (arr3 = finalArr)
}

export const survey = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SURVEYS":
    case "GET_SURVEY":
    case "COPY_SURVEY":
      return {
        ...state, loading: action.payload, survey: null
      };
    case "ADD_QUESTION":
      return {
        ...state, loading: action.payload, survey: null, surveyUpdated: null
      };
    case "EDIT_TITLE":
    case "EDIT_SLOGAN":
    case "EDIT_SUBTITLE":
    case "EDIT_SEND_TEXT":
    case "EDIT_GRATITUDE_TEXT":
    case "EDIT_QUESTION":
    case "EDIT_CONDITION":
    case "DELETE_QUESTION":
    case "ADD_PAGE":
    case "DELETE_PAGE":
    case "COPY_QUESTION":
    case "SELECTED_TEMPLATE":
    case "EDIT_TEMPLATE":
    case "ADD_LIBRARY_QUESTION":
    case "MOVE_QUESTION":
      return {
        ...state, loading: action.payload, surveyUpdated: null
      };
    case "GET_LIBRARY_QUESTION":
      return {
        ...state
      };
    case "DELETE_SURVEY":
      return {
        ...state,
        loading: true,
        surveyDelete: action.payload
      };
    case "GET_SURVEYS_SUCCESS":
      return {
        ...state, loading: false, error: false, surveys: action.payload
      };
    case "GET_LIBRARY_QUESTION_SUCCESS":
      return {
        ...state, loading: false, questions: action.payload
      };
    case "GET_SURVEY_PREVIEW_SUCCESS":
    case "GET_SURVEY_SUCCESS":
      return {
        ...state, loading: false, survey: action.payload
      };
    case "COPY_SURVEY_SUCCESS":
      return {
        ...state, loading: false, surveys: [...state.surveys, action.payload]
      };
    case "ADD_QUESTION_SUCCESS":
    case "MOVE_QUESTION_SUCCESS":
    case "ADD_LIBRARY_QUESTION_SUCCESS":
    case "EDIT_QUESTION_SUCCESS":
    case "EDIT_TITLE_SUCCESS":
    case "EDIT_SLOGAN_SUCCESS":
    case "EDIT_SUBTITLE_SUCCESS":
    case "EDIT_SEND_TEXT_SUCCESS":
    case "EDIT_GRATITUDE_TEXT_SUCCESS":
    case "EDIT_CONDITION_SUCCESS":
    case "DELETE_QUESTION_SUCCESS":
    case "ADD_PAGE_SUCCESS":
    case "DELETE_PAGE_SUCCESS":
    case "COPY_QUESTION_SUCCESS":
    case "SELECTED_TEMPLATE_SUCCESS":
    case "EDIT_TEMPLATE_SUCCESS":
      return {
        ...state, loading: false, surveyUpdated: true
      };
    case "DELETE_SURVEY_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        surveys: state.surveys.filter(s => s.id !== state.surveyDelete),
        surveyDelete: null
      };
    case "GET_SURVEYS_ERROR":
    case "GET_SURVEY_ERROR":
    case "GET_SURVEY_PREVIEW_ERROR":
    case "COPY_SURVEY_ERROR":
    case "ADD_QUESTION_ERROR":
    case "EDIT_QUESTION_ERROR":
    case "DELETE_QUESTION_ERROR":
    case "DELETE_PAGE_ERROR":
    case "COPY_QUESTION_ERROR":
    case "ADD_PAGE_ERROR":
    case "EDIT_CONDITION_ERROR":
    case "EDIT_TITLE_ERROR":
    case "EDIT_SLOGAN_ERROR":
    case "EDIT_SUBTITLE_ERROR":
    case "EDIT_SEND_TEXT_ERROR":
    case "EDIT_GRATITUDE_TEXT_ERROR":
    case "DELETE_SURVEY_ERROR":
    case "SELECTED_TEMPLATE_ERROR":
    case "EDIT_TEMPLATE_ERROR":
    case "GET_LIBRARY_QUESTION_ERROR":
    case "ADD_LIBRARY_QUESTION_ERROR":
    case "MOVE_QUESTION_ERROR":
      return {
        ...state, loading: false, error: action.payload
      };
    default:
      return { ...state };
  }
};

