export const tokenMiddleware = store => next => action => {
  switch (action.type) {
    case "LOGIN_WITH_JWT":
      break;
    case "USER_PROFILE_ERROR":
      break;
    default:
      break;
  }
  next(action)
}