import { combineReducers } from "redux"
import { project } from "./projectsReducers"
import { wave } from "./waveReducers"

const projectsReducers = combineReducers({
    project,
    wave
})

export default projectsReducers
