const initialState = {
    messageError: null,
    messageSuccess: null
  };
  
export const signup = (state = initialState, action) => {
  switch (action.type) {
    case "SIGNUP": {
      return { ...state, messageError: null, messageSuccess: null };
    }
    case "SIGNUP_SUCCESS": {
      return { ...state, messageSuccess: action.payload };
    }
    case "SIGNUP_ERROR": {
      return { ...state, messageError: action.payload };
    }
    default: {
      return state;
    }
  }
};
