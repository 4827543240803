const initialState = {
  waves: [],
  suscriptors: [],
  answers: [],
  survey: null,
  waveAnonymous: null,
  error: null,
  loading: false,
  sortIndex: [0, 0],
  totalRecords: 0,
  waveCreated: null,
  questionEmbebbed: null
};

const getIndex = (arr3, perPage, currentPage, totalRecords) => {
  let startIndex = (perPage * (currentPage - 1)) + 1
  let endIndex = perPage * currentPage
  if (totalRecords < endIndex) {
    endIndex = totalRecords
  }
  let finalArr = [startIndex, endIndex]
  return (arr3 = finalArr)
}

export const wave = (state = initialState, action) => {
  switch (action.type) {
    case "GET_WAVES_BUILDER":
    case "ADD_REMINDER":
    case "GET_QUESTION_EMBEBBED":
    case "SEND_TEST_EMAIL":
      return {
        ...state, loading: action.payload
      };
    case "GET_TARGET_DATA":
      return {
        ...state, loading: action.payload, survey: null
      };
    case "GET_ANSWERS_SUSCRIPTORS":
    case "GET_ANSWERS_FILL_SUSCRIPTORS":
      return {
        ...state, loading: action.payload, waveTargets: []
      };
    case "GET_ANSWER_PAGINATE":
      return {
        ...state, loading: action.payload, answers: []
      };
    case "ADD_WEB_LINK":
    case "SEND_PROJECT":
      return {
        ...state,
        loading: true,
        waveCreated: null
      };
    case "GET_ANSWERS_SUSCRIPTORS_SUCCESS":
    case "GET_ANSWERS_FILL_SUSCRIPTORS_SUCCESS":
      return {
        ...state,
        loading: false,
        waveTargets: action.payload.waveTargets,
        totalRecords: action.payload.total_items_count,
        rowsPerPage: action.payload.rows_per_page,
        totalPages: action.payload.total_pages,
        currentPage: action.payload.current_page,
        sortIndex: getIndex(state.sortIndex, action.payload.rows_per_page, action.payload.current_page, action.payload.total_items_count)
      };
    case "GET_ANSWER_PAGINATE_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        answers: action.payload.answers,
        totalRecords: action.payload.total_items_count,
        rowsPerPage: action.payload.rows_per_page,
        totalPages: action.payload.total_pages,
        currentPage: action.payload.current_page,
        sortIndex: getIndex(state.sortIndex, action.payload.rows_per_page, action.payload.current_page, action.payload.total_items_count)
      };
    case "GET_TARGET_DATA_SUCCESS":
      return {
        ...state, loading: false, error: false, survey: action.payload
      };
    case "GET_QUESTION_EMBEBBED_SUCCESS":
      return {
        ...state, loading: false, error: false, questionEmbebbed: action.payload
      };
    case "GET_WAVES_BUILDER_SUCCESS":
      return {
        ...state, loading: false, waves: action.payload
      };
    case "SEND_TEST_EMAIL_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false
      };
    case "ADD_WEB_LINK_SUCCESS":
    case "SEND_PROJECT_SUCCESS":
      return {
        ...state,
        loading: false,
        waveCreated: action.payload,
        waves: state.waves !== null ? [...state.waves, action.payload] : [action.payload],
      };
    case "EDIT_REMINDER_SUCCESS":
      return {
        ...state,
        loading: false,
        waves: state.waves.map(w => w.id === action.payload.wave ? { ...w, reminders: w.reminders.map(r => r.id === action.payload.id ? r = action.payload : r) } : w),
      };
    case "ADD_REMINDER_SUCCESS":
      return {
        ...state,
        loading: false,
        waves: state.waves.map(w => w.id === action.payload.wave ? { ...w, reminders: [...w.reminders, action.payload] } : w),
      };
    case "DELETE_REMINDER_SUCCESS":
      return {
        ...state,
        loading: false,
        waves: state.waves.map(w => w.id === action.payload.wave ? { ...w, reminders: w.reminders.filter(r => r.id !== action.payload.id) } : w),
      };
    case "GET_WAVES_BUILDER_ERROR":
    case "GET_ANSWERS_SUSCRIPTORS_ERROR":
    case "GET_ANSWERS_SUSCRIPTORS_FILL_ERROR":
    case "GET_TARGET_DATA_ERROR":
    case "ADD_REMINDER_ERROR":
    case "EDIT_REMINDER_ERROR":
    case "DELETE_REMINDER_ERROR":
    case "GET_ANSWER_PAGINATE_ERROR":
    case "GET_ANSWERS_FILL_SUSCRIPTORS_ERROR":
    case "ADD_WEB_LINK_ERROR":
    case "SEND_PROJECT_ERROR":
    case "GET_QUESTION_EMBEBBED_ERROR":
    case "SEND_TEST_EMAIL_ERROR":
      return {
        ...state, loading: false, error: action.payload
      };
    default:
      return { ...state };
  }
};

