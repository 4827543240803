const initialState = {
    currentContract: null,
    error: null,
    loading: false
};

const contract = (state = initialState, action) => {
    switch (action.type) {
        case "GET_CONTRACT":
            return { ...state, loading: action.payload };
        case "GET_CONTRACT_SUCCESS":
            return { ...state, loading: false, currentContract: action.payload };
        case "GET_CONTRACT_ERROR":
            return { ...state, loading: false, error: action.payload };
        default:
            return { ...state };
    }
};

export default contract