import React, { useState, Fragment } from "react"
import { useDispatch } from "react-redux"

import "../../../../assets/scss/pages/data-list.scss"
import { Check } from "react-feather"
import {
  Input,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  FormGroup,
  ButtonGroup,
  Button,
  UncontrolledTooltip,
  CustomInput,
  Label
} from "reactstrap"
import "../../../../assets/scss/custom.scss"
import Radio from "../../../../components/@vuexy/radio/RadioVuexy"
import Checkbox from "../../../../components/@vuexy/checkbox/CheckboxesVuexy"
import { FormattedMessage } from "react-intl"
import { Editor } from "@tinymce/tinymce-react";
import { editTranslate } from "../../../../redux/actions/surveys/surveyTranslate"

const TranslateData = ({ data, setCurrentData, survey }) => {
  const dispatch = useDispatch()

  const textGeneralRef = React.useRef(null);
  const textGeneralTransRef = React.useRef(null);
  const textQuestionRef = React.useRef(null);
  const textQuestionTransRef = React.useRef(null);
  const [textTranslations, setTextTranslations] = useState(data.textTranslations);

  React.useLayoutEffect(() => {
    if (textGeneralRef.current !== null) textGeneralRef.current.style.height = `${Math.max(textGeneralRef.current.scrollHeight, 50)}px`;
    if (textGeneralTransRef.current !== null) textGeneralTransRef.current.style.height = `${Math.max(textGeneralTransRef.current.scrollHeight, 50)}px`;
    if (textQuestionRef.current !== null) textQuestionRef.current.style.height = `${Math.max(textQuestionRef.current.scrollHeight, 50)}px`;
    if (textQuestionTransRef.current !== null) textQuestionTransRef.current.style.height = `${Math.max(textQuestionTransRef.current.scrollHeight, 50)}px`;
  }, []);

  const adjustContent = (e) => {
    e.target.style.height = e.target.scrollHeight + 'px';
  }

  const changeText = e => {
    setTextTranslations(textTranslations.map(t => t.id === e.target.name ? { ...t, text: e.target.value } : t))
  };

  const handleEditorChange = (content, editor) => {
    setTextTranslations(textTranslations.map(t => t.id === editor.id ? { ...t, text: content } : t))
  };

  const findText = (type, targetId) => {
    let textFound = textTranslations.find(t => t.target_type === type && t.target_id === targetId)

    return textFound
  };

  const arrayScale = (min, max) => {
    let arrayNumber = []

    for (let i = Number(min); i <= max; i++) {
      arrayNumber.push(i)
    }

    return arrayNumber
  }

  const saveTranslate = () => {
    dispatch(editTranslate(survey, { languageId: data.languageTranslation, dataType: data.dataType, questionId: data.id, textTranslate: JSON.stringify(textTranslations) }))
    setCurrentData(null)
  }

  return (
    <Fragment>
      <Row className="mt-2">
        <Col xs="12" sm="6">
          <Card>
            <CardHeader className="">
              <span className="text-bold-600 mr-1 translate-title">
                <FormattedMessage id={data.dataType} />{data.dataType === 'question' && " " + data.pos}
              </span>
              <div className="border-light rounded">
                <div className="px-1 div-border">
                  <FormattedMessage id={data.language} />
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {data.dataType === 'question' ?
                <Fragment>
                  <Input
                    className="border-0 input-survey mb-1"
                    type="textarea"
                    value={data.title}
                    innerRef={textQuestionRef}
                    id="tooltipQuestionTitle1"
                    onKeyUp={adjustContent}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.subtitle,
                    }}
                  ></div>
                  {data.answer.type === "Text" ?
                    <Input
                      type="textarea"
                      value={data.answer.placeholder}
                    >
                    </Input>
                    : data.answer.type === "Scale" ?
                      <Fragment>
                        <ButtonGroup className="w-100">
                          {data.answer.dontknow &&
                            <FormGroup
                              className="d-flex btn-scale-preview rounded custom-scale"
                              style={{ width: 100 / arrayScale(data.answer.valueMin, data.answer.valueMax).length + '%' }}
                            >
                              <CustomInput
                                type='radio'
                              />
                              <Label className="scale-val">NS/NC</Label>
                            </FormGroup>
                          }
                          {arrayScale(data.answer.valueMin, data.answer.valueMax).map(i =>
                            <FormGroup
                              className="d-flex btn-scale-preview rounded custom-scale"
                              style={{ width: 100 / arrayScale(data.answer.valueMin, data.answer.valueMax).length + '%' }}
                            >
                              <CustomInput
                                key={"option-" + + i}
                                type='radio'
                              />
                              <Label className="scale-val">{i}</Label>
                            </FormGroup>
                          )}
                        </ButtonGroup>

                        <div className="w-100 d-flex justify-content">
                          <small className="text-left">{data.answer.textValueMin}</small>
                          <Col className="pr-0"><div className="text-right"><small className="mr-1">{data.answer.textValueMax}</small></div></Col>
                        </div>
                      </Fragment>
                      : <FormGroup className="text-center">
                        {data.answer.options && data.answer.options.length > 0 && data.answer.minAnswersSelected === data.answer.maxAnswersSelected && data.answer.options.map(option => (
                          <div className="d-flex justify-content-between mt-options">
                            <Radio
                              key={option.id}
                              name="options"
                            />
                            <Input
                              type="text"
                              value={option.text}
                            />
                          </div>
                        ))}
                        {data.answer.options && data.answer.options.length > 0 && data.answer.minAnswersSelected !== data.answer.maxAnswersSelected && data.answer.options.map(option => (
                          <div className="d-flex justify-content-between mt-options">
                            <Checkbox
                              color="primary"
                              icon={<Check className="vx-icon" size={16} />}
                              label={option.text}
                              className="mb-1"
                            />
                            <Input
                              type="text"
                              value={option.text}
                            />
                          </div>
                        ))}
                      </FormGroup>
                  }
                </Fragment>
                :
                <Fragment>
                  <Input
                    type="textarea"
                    value={data.title}
                    innerRef={textGeneralRef}
                  />
                </Fragment>

              }

            </CardBody>
          </Card>
        </Col>
        <Col xs="12" sm="6">
          <Card>
            <CardHeader className="">
              <span className="text-bold-600 mr-1 translate-title">
                <FormattedMessage id={data.dataType} />{data.dataType === 'question' && " " + data.pos}
              </span>
              <div className="border-light rounded">
                <div className="px-1 div-border">
                  <FormattedMessage id={data.languageTranslation} />
                </div>
              </div>
            </CardHeader>
            <CardBody>
              {data.dataType === 'question' ?
                <Fragment>
                  <Input
                    className="border-0 input-survey mb-1"
                    name={findText("question-title", data.id).id}
                    type="textarea"
                    value={findText("question-title", data.id).text}
                    onChange={changeText}
                    innerRef={textQuestionTransRef}
                    id="tooltipQuestionTitle"
                  />
                  <UncontrolledTooltip placement="top" target="tooltipQuestionTitle">
                    <FormattedMessage id="tooltipQuestionTitle" />
                  </UncontrolledTooltip>
                  {findText("question-subtitle", data.id).text !== "" &&
                    <div className="mb-1">
                      <Editor
                        value={findText("question-subtitle", data.id).text}
                        apiKey={process.env.REACT_APP_TINY}
                        init={{
                          height: 200,
                          readonly: 1,
                          language: "es",
                          menubar: false,
                          plugins: [
                            "advlist lists",
                            "table paste"
                          ],
                          toolbar:
                            "formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent"
                        }}
                        onEditorChange={(content, editor) => handleEditorChange(content, editor)}
                        id={findText("question-subtitle", data.id).id}
                      />
                    </div>
                  }
                  {data.answer.type === "Text" ?
                    <Input
                      name={findText("answer-text-placeholder", data.answer.id).id}
                      type="textarea"
                      value={findText("answer-text-placeholder", data.answer.id).text}
                      onChange={changeText}
                    >
                    </Input>
                    : data.answer.type === "Scale" ?
                      <Fragment>
                        <ButtonGroup className="w-100">
                          {data.answer.dontknow &&
                            <FormGroup
                              className="d-flex btn-scale-preview rounded custom-scale"
                              style={{ width: 100 / arrayScale(data.answer.valueMin, data.answer.valueMax).length + '%' }}
                            >
                              <CustomInput
                                type='radio'
                              />
                              <Label className="scale-val">NS/NC</Label>
                            </FormGroup>
                          }
                          {arrayScale(data.answer.valueMin, data.answer.valueMax).map(i =>
                            <FormGroup
                              className="d-flex btn-scale-preview rounded custom-scale"
                              style={{ width: 100 / arrayScale(data.answer.valueMin, data.answer.valueMax).length + '%' }}
                            >
                              <CustomInput
                                key={"option-" + + i}
                                type='radio'
                              />
                              <Label className="scale-val">{i}</Label>
                            </FormGroup>
                          )}
                        </ButtonGroup>

                        <div className="w-100 d-flex justify-content">
                          <small className="text-left">{data.answer.textValueMin}</small>
                          <Col className="pr-0"><div className="text-right"><small className="mr-1">{data.answer.textValueMax}</small></div></Col>
                        </div>
                      </Fragment>
                      : <FormGroup className="text-center">
                        {data.answer.options && data.answer.options.length > 0 && data.answer.minAnswersSelected === data.answer.maxAnswersSelected && data.answer.options.map(option => (
                          <div className="d-flex justify-content-between mt-options">
                            <Radio
                              key={option.id}
                              name="options"
                            />
                            <Input
                              name={findText("answer-optionlist-text", option.id).id}
                              type="text"
                              value={findText("answer-optionlist-text", option.id).text}
                              onChange={changeText}
                            />
                          </div>
                        ))}
                        {data.answer.options && data.answer.options.length > 0 && data.answer.minAnswersSelected !== data.answer.maxAnswersSelected && data.answer.options.map(option => (
                          <div className="d-flex justify-content-between mt-options">
                            <Checkbox
                              color="primary"
                              icon={<Check className="vx-icon" size={16} />}
                              label={option.text}
                              className="mb-1"
                            />
                            <Input
                              name={findText("answer-optionlist-text", option.id).id}
                              type="text"
                              value={findText("answer-optionlist-text", option.id).text}
                              onChange={changeText}
                            />
                          </div>
                        ))}
                      </FormGroup>
                  }
                </Fragment>
                :
                <Fragment>
                  <Input
                    name={data.textTranslations[0].id}
                    type="textarea"
                    value={textTranslations[0].text}
                    onChange={changeText}
                    innerRef={textGeneralTransRef}
                  />
                </Fragment>
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
      <div className="data-list-header d-flex justify-content-between flex-wrap ml-1">
        <div className="actions-left d-flex flex-wrap"></div>
        <div className="actions-right d-flex flex-wrap mt-sm-0 mt-2">
          <Button
            className="btn-primary mr-2"
            color="white"
            onClick={() => saveTranslate()}
            outline
          >
            <span className="align-middle"><FormattedMessage id="save" /></span>
          </Button>
          <Button
            className="mr-2"
            color="danger"
            outline
            onClick={() => setCurrentData(null)}
          >
            <span className="align-middle"><FormattedMessage id="cancel" /></span>
          </Button>
        </div>
      </div>
    </Fragment>
  )
}

export default TranslateData
