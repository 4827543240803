import React, { useState, useEffect, Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FormattedMessage } from "react-intl"

import DataTable from "react-data-table-component"
import Spinner from "../../../../components/@vuexy/spinner/Loading-spinner"
import "../../../..//assets/scss/plugins/extensions/react-paginate.scss"
import "../../../../assets/scss/pages/data-list.scss"
import {
  ChevronLeft,
  ArrowLeft,
  ChevronRight,
  ChevronDown
} from "react-feather"
import ReactPaginate from "react-paginate"
import {
  Button,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Input
} from "reactstrap"
import { getAllQuestions } from "../../../../redux/actions/surveys/surveyTranslate"
import TranslateData from "./TranslateData"
import { Link } from "react-router-dom"

const TranslateQuestionList = ({ location }) => {
  const dispatch = useDispatch()

  const [currentData, setCurrentData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchText, setSearchText] = useState(null);

  const questions = useSelector(state => state.surveys.surveyTranslate.questions)
  const loading = useSelector(state => state.surveys.surveyTranslate.loading)
  const totalRecords = useSelector(state => state.surveys.surveyTranslate.totalRecords)
  const totalPages = useSelector(state => state.surveys.surveyTranslate.totalPages)
  const sortIndex = useSelector(state => state.surveys.surveyTranslate.sortIndex)

  let columns =
    [
      {
        selector: "title",
        sortable: true,
        minWidth: "300px",
        maxWidth: "1000px",
        cell: row =>
        (
          <p className="text-truncate mb-0" onClick={() => setCurrentData(row)}>
            <span className="text-bold-500"><FormattedMessage id={row.dataType} />{row.dataType === 'question' && " " + row.pos}</span> : {row.title}
          </p>
        )
      }
    ]

  const changeRowPerPage = (rowValue) => {
    setRowsPerPage(rowValue)
    setCurrentPage(1)
  }

  const CustomHeader = props => {
    return (
      <div className="data-list-header d-flex justify-content-between flex-wrap">
        <div className="actions-left d-flex flex-wrap">
          <Link to={{ pathname: '/survey/translations', state: { id: location.state.survey } }}>
            <Button
              color="primary"
              outline
            >
              <ArrowLeft size={18} />{" "}
              <span className="align-middle"><FormattedMessage id="backEditor" /></span>
            </Button>
          </Link>
        </div>
        <div className="actions-right d-flex flex-wrap mt-sm-0 mt-2">
          <UncontrolledDropdown className="data-list-rows-dropdown mr-1 d-md-block d-none">
            <DropdownToggle color="" className="sort-dropdown">
              <span className="align-middle mx-50">
                {`${props.index[0]} - ${props.index[1]} de ${props.total}`}
              </span>
              <ChevronDown size={15} />
            </DropdownToggle>
            <DropdownMenu tag="div" right>
              <DropdownItem tag="a" onClick={() => props.changeRowPerPage(5)}>
                5
              </DropdownItem>
              <DropdownItem tag="a" onClick={() => props.changeRowPerPage(10)}>
                10
              </DropdownItem>
              <DropdownItem tag="a" onClick={() => props.changeRowPerPage(15)}>
                15
              </DropdownItem>
              <DropdownItem tag="a" onClick={() => props.changeRowPerPage(20)}>
                20
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <div className="filter-section">
            <Input type="text" onChange={e => props.setSearchText(e.target.value)} />
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    dispatch(getAllQuestions(location.state.survey, location.state.language_id, currentPage, rowsPerPage, searchText === "" ? null : searchText))
  }, [currentPage, rowsPerPage, searchText])

  if (loading) return <Spinner />;

  return (
    <Fragment>
      {currentData === null ?
        <div className="data-list list-noHeader">
          <DataTable
            columns={columns}
            data={questions}
            noDataComponent="No hay datos que mostrar"
            pagination
            paginationServer
            paginationComponent={() => (
              <ReactPaginate
                previousLabel={<ChevronLeft size={15} />}
                nextLabel={<ChevronRight size={15} />}
                pageCount={totalPages || 1}
                activeClassName="active"
                forcePage={currentPage !== 0 ? parseInt(currentPage - 1) : 0}
                onPageChange={page => setCurrentPage(page.selected + 1)}
                containerClassName={'pagination react-paginate justify-content-end my-2 pr-1'}
                pageClassName={'page-item'}
                nextLinkClassName={'page-link'}
                nextClassName={'page-item next'}
                previousClassName={'page-item next'}
                previousLinkClassName={'page-link'}
                pageLinkClassName={'page-link'}
              />
            )}
            noHeader
            noTableHead={true}
            subHeader
            subHeaderComponent={
              <CustomHeader
                changeRowPerPage={changeRowPerPage}
                setSearchText={setSearchText}
                rowsPerPage={rowsPerPage}
                total={totalRecords}
                index={sortIndex}
              />
            }
            responsive
            pointerOnHover
            sortIcon={<ChevronDown />}
          />
        </div>
        :
        <TranslateData
          data={currentData}
          setCurrentData={setCurrentData}
          survey={location.state.survey}
        />
      }
    </Fragment>
  )
}

export default TranslateQuestionList
