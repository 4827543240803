const initialState = {
  templates: [],
  messageTemplates: [],
  // messageTemplates: [],
  invitationTemplates: [],
  gratitudeTemplates: [],
  subjectTemplates: [],
  template: null,
  templateCreated: false,
  error: null,
  loading: false,
  sortIndex: [0, 0],
  totalRecords: 0
};

const getIndex = (arr3, perPage, currentPage, totalRecords) => {
  let startIndex = (perPage * (currentPage - 1)) + 1
  let endIndex = perPage * currentPage
  if (totalRecords < endIndex) {
    endIndex = totalRecords
  }
  let finalArr = [startIndex, endIndex]
  return (arr3 = finalArr)
}

export const template = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TEMPLATE":
    case "GET_TEMPLATES":
    case "EDIT_TEMPLATE":
    case "COPY_TEMPLATE":
    case "GET_ALL_TEMPLATES":
    case "GET_MESSAGE_TEMPLATES_BY_TYPE":
    case "GET_TEMPLATES_BY_EMAIL":
    case "GET_TEMPLATES_BY_GRATITUDE":
    case "GET_TEMPLATES_BY_SUBJECT":
    case "ADD_MESSAGE_TEMPLATE":
    case "EDIT_MESSAGE_TEMPLATE":
      return { ...state, loading: action.payload };
    case "GET_MESSAGE_TEMPLATES":
    case "GET_MESSAGE_TEMPLATES_TRANSLATION":
      return { ...state, loading: action.payload, messageTemplates: [] };
    case "ADD_TEMPLATE":
      return { ...state, loading: action.payload, templateCreated: false };
    case "DELETE_TEMPLATE":
      return {
        ...state,
        loading: true,
        templateDelete: action.payload
      };
    case "DELETE_MESSAGE_TEMPLATE":
      return {
        ...state,
        loading: true,
        messageTemplateDelete: action.payload
      };
    case "GET_ALL_TEMPLATES_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        templates: action.payload
      };
    case "GET_MESSAGE_TEMPLATES_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        messageTemplates: action.payload.templates,
        totalRecords: action.payload.total_items_count,
        rowsPerPage: action.payload.rows_per_page,
        totalPages: action.payload.total_pages,
        currentPage: action.payload.current_page,
        sortIndex: getIndex(state.sortIndex, action.payload.rows_per_page, action.payload.current_page, action.payload.total_items_count)
      };
    case "GET_MESSAGE_TEMPLATES_TRANSLATION_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        messageTemplates: action.payload.templates !== null ? action.payload.templates : [],
        totalRecords: action.payload.total_items_count,
        rowsPerPage: action.payload.rows_per_page,
        totalPages: action.payload.total_pages,
        currentPage: action.payload.current_page,
        sortIndex: getIndex(state.sortIndex, action.payload.rows_per_page, action.payload.current_page, action.payload.total_items_count)
      };
    case "GET_TEMPLATE_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        template: action.payload,
      };
    case "GET_TEMPLATES_SUCCESS":
    case "GET_MESSAGE_TEMPLATES_BY_TYPE_SUCCESS":
      return {
        ...state, loading: false, error: false, messageTemplates: action.payload
      };
    case "GET_TEMPLATES_BY_EMAIL_SUCCESS":
      return {
        ...state, loading: false, error: false, invitationTemplates: action.payload
      };
    case "GET_TEMPLATES_BY_GRATITUDE_SUCCESS":
      return {
        ...state, loading: false, error: false, gratitudeTemplates: action.payload
      };
    case "GET_TEMPLATES_BY_SUBJECT_SUCCESS":
      return {
        ...state, loading: false, error: false, subjectTemplates: action.payload
      };
    case "EDIT_TEMPLATE_SUCCESS":
      return { ...state, loading: false };
    case "ADD_TEMPLATE_SUCCESS":
      return {
        ...state, loading: false, templates: [...state.templates, action.payload], templateCreated: true
      };
    case "ADD_MESSAGE_TEMPLATE_SUCCESS":
      return {
        ...state,
         loading: false,
         messageTemplates: !action.payload.isTranslation ? [...state.messageTemplates, action.payload] : [...state.messageTemplates]
      };
    case "EDIT_MESSAGE_TEMPLATE_SUCCESS":
      return {
        ...state, 
        loading: false, 
        messageTemplates: state.messageTemplates.map(msg => msg.id === action.payload.id ? msg = action.payload : msg )
      };
    case "COPY_TEMPLATE_SUCCESS":
      return {
        ...state, loading: false, templates: [...state.templates, action.payload]
      };
    case "DELETE_TEMPLATE_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        templates: state.templates.filter(t => t.id !== state.templateDelete),
        templateDelete: null
      };
    case "DELETE_MESSAGE_TEMPLATE_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        messageTemplates: state.messageTemplates.filter(t => t.id !== state.messageTemplateDelete),
        messageTemplateDelete: null
      };
    case "GET_TEMPLATE_ERROR":
    case "GET_TEMPLATES_ERROR":
    case "GET_MESSAGE_TEMPLATES_ERROR":
    case "GET_MESSAGE_TEMPLATES_TRANSLATION_ERROR":
    case "GET_MESSAGE_TEMPLATES_BY_TYPE_ERROR":
    case "GET_TEMPLATES_BY_EMAIL_ERROR":
    case "GET_TEMPLATES_BY_GRATITUDE_ERROR":
    case "GET_TEMPLATES_BY_SUBJECT_ERROR":
    case "GET_ALL_TEMPLATES_ERROR":
    case "ADD_TEMPLATE_ERROR":
    case "ADD_MESSAGE_TEMPLATE_ERROR":
    case "EDIT_MESSAGE_TEMPLATE_ERROR":
    case "EDIT_TEMPLATE_ERROR":
    case "DELETE_TEMPLATE_ERROR":
    case "DELETE_MESSAGE_TEMPLATE_ERROR":
    case "COPY_TEMPLATE_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return { ...state };
  }
};
