import React from "react"
import themeConfig from "../configs/themeConfig"
import classnames from "classnames"

const SurveyLayout = ({ children, ...rest }) => {
  return (
    <div
      className={classnames("blank-page")}
    >
      <div className="app-content">
        <main className="main w-100">{children}</main>
      </div>
    </div>
  )
}

export default SurveyLayout
